import React from "react";
import SEO from "../../components/seo";
import Blog from "../../components/Blog";
import Par from "../../components/Par";
import H1 from "../../components/h1";

const title = "Heartburn (Reflux)";

// Download link - http://www.fbrennangastro.com.au/downloads/gesaheartburn.pdf

export default () => (
	<Blog title={title}>
		<SEO
			title={title}
			keywords={[title, "gastroesophageal reflux", "indigestion", "reflux", "heartburn", "antacids"]}
			type="article"
			description="Heartburn (or reflux) - causes, symptoms and lifestyle factors that have an effect"
		/>
		<H1>{title}</H1>
		<Par>
			Heartburn (gastroesophageal reflux or indigestion) is very common. Most healthy people experience heartburn from
			time to time. Antacid is a suitable treatment for healthy people, but severe heartburn requires medical attention.
		</Par>
		<Par>
			Heartburn (gastroesophageal reflux) is due to the contents of the stomach coming back up into the oesophagus where
			it can cause a burning sensation in the chest or throat.
		</Par>
		<Par>
			Persistent reflux that occurs more than twice a week is considered to be gastroesophageal reflux disease (GORD),
			and it can eventually lead to more serious health problems. People of all ages can have GORD.
		</Par>
		<Par>
			Occasional heartburn can be caused by eating a large meal, doing physical activity too soon after eating, too much
			coffee or alcohol, anxiety and advanced pregnancy. Cases of persistent heartburn are usually caused by obesity.
		</Par>
	</Blog>
);
